<template>
  <NavBar />
  <AppBar />

  <v-container>
    <v-card>
      <v-card-item>
        Welcome to our Cemetery Community
      </v-card-item>
      <v-card-item>
        <v-progress-circular v-if="!version" indeterminate size="20"/>
        {{ version ? "Version - " + version.serverversion : "" }}
      </v-card-item>
      <v-card-text>This is a demo version of the Cemetery Community software created by Dan's Tech Services - Designed to help cemeteries manage their grounds electronically no matter their size.</v-card-text>
      <v-card-text>Feel free to explore and see the many different tools our software offers!</v-card-text>
      <v-card-text>If you have any questions or want to learn more, you can reach us at by clicking or tapping one of the options below.</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn link href="mailto:dan@danstechservices.us">Email</v-btn>
        <v-btn link href="tel:8103283419">810-328-3419</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBar from '@/components/NavBar.vue';
import AppBar from '@/components/AppBar.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    NavBar,
    AppBar
  },

  data: () => ({
    version : null
  }),

  created: function(){
    this.getVersion();
  },

  methods:{
    getVersion(){
      fetch("https://demo.cemeterycommunity.com:9600/api/getversion", { method: "POST" })
      .then( res => res.json() )
      .then( res =>  {
        this.version = res.results;
      });
    }
  }
});
</script>