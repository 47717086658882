<template>
  <v-dialog max-width="80%" persistent>
    <template v-slot:activator="{ props: activatorProps }">
      <v-list-item v-bind="activatorProps">Login</v-list-item>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card>
        <v-card-title>Login</v-card-title>
        <v-card-subtitle>Cemetery Community</v-card-subtitle>

        <v-card-item>Login is disabled on the demo!</v-card-item>

        <v-card-item>
          <v-text-field label="Username" />
        </v-card-item>
        <v-card-item>
          <v-text-field type="password" label="Password" />
        </v-card-item>

        <v-card-actions>
          <v-btn @click="isActive.value = false">Login</v-btn>
        </v-card-actions>

      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'AuthComp',

  props:{
    user : Object
  },

  data: () => ({

  }),
}
</script>