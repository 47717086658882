import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue')
  },
  {
    path: '/spaces',
    name: 'spaces',
    component: () => import('../views/SpacesView.vue')
  },
  {
    path: '/people',
    name: 'people',
    component: () => import('../views/PeopleView.vue')
  },
  {
    path: '/burials',
    name: 'burials',
    component: () => import('../views/BurialsView.vue')
  },
  {
    path: '/workorders',
    name: 'workorders',
    component: () => import('../views/WorkOrdersView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/AdminView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router