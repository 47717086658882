<template>
  <v-navigation-drawer>
    <v-list-item v-if="user" :title="user.cemeteryname" subtitle="Cemetery Community"></v-list-item>
    <v-list-item title="Cemetery Community" subtitle="Demo"></v-list-item>
    <v-divider></v-divider>
    <v-list-item link to="/" title="Home"></v-list-item>
    <v-list-item link to="/dashboard" title="Dashboard" />
    <v-list-item link to="/spaces" title="Spaces" />
    <v-list-item link to="/people" title="People" />
    <v-list-item link to="/burials" title="Burials" />
    <v-list-item link to="/workorders" title="Work Orders" />
    <v-list-item link to="/admin" title="Admin" />
    <template v-slot:append>
      <AuthComp @authenticated="storeAuthUser" />
    </template>

    
  </v-navigation-drawer>
</template>

<script>
import AuthComp from './AuthComp.vue';

export default {
  name: 'NavBar',
  components:{
    AuthComp
  },

  data: () => ({
    user : null
  }),

  methods:{
    storeAuthUser(user){
      this.user = user;
    }
  }
}
</script>