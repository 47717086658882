<template>
  <v-app-bar :elevation="2" class="hidden-lg-and-up">
    <template v-slot:prepend>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-app-bar-nav-icon v-bind="props" />
        </template>
        <v-list>
          <v-list-item link to="/" title="Home"></v-list-item>
          <v-list-item link to="/dashboard" title="Dashboard" />
          <v-list-item link to="/spaces" title="Spaces" />
          <v-list-item link to="/people" title="People" />
          <v-list-item link to="/burials" title="Burials" />
          <v-list-item link to="/workorders" title="Work Orders" />
          <v-list-item link to="/admin" title="Admin" />
          <AuthComp />
        </v-list>
      </v-menu>
    </template>

    <v-app-bar-title>Cemetery Community - Demo</v-app-bar-title>
  </v-app-bar>
</template>

<script>
import AuthComp from './AuthComp.vue';

export default {
  name: 'AppBar',
  components : {
    AuthComp
  },

  data: () => ({

  }),
}
</script>